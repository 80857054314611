






















import {Component, Vue} from 'vue-property-decorator';
import CompanyInfoForm from '@/components/business/CompanyInfoForm.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import {applicationStore} from '@/store/modules/application';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import {mixins} from "vue-class-component";

@Component({
  name: 'CompanyProfile',
  components: {CompanyInfoForm}
})
export default class CompanyProfile extends mixins(Vue, ContainerCalc) {

  get busy() {
    return businessStore.saving
  }

  async onUpdateCompanyInfo(payload: any) {
    if (await businessStore.updateCompanyInfo(payload)) {
      await applicationStore.loadBusiness()
    }
  }

  updated() {
    this.calcHeight(this.$refs.container);
  }

  mounted() {
    this.calcHeight(this.$refs.container);
  }
}
